import React, { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import { navigate } from "gatsby";

import { getStorage, setStorage } from "../utils";
import ButtonField from "./common/form/ButtonField";
// import request from "../services/request";
import { OFFER_ADDRESS_EDIT_SCREEN, OFFER_STEP_SECOND_SCREEN } from "../constant";

const AddressVerify = () => {
  const [formValue, setFormValue] = useState({})
  useEffect(() => {
    setTimeout(() => {
      setFormValue(getStorage('formData') ?? {})
    }, 200);
    let formData = getStorage('formData')
    const myLatLng = { lat: formData?.latitude, lng: formData?.longitude };
    setTimeout(() => {
      if (typeof window.google !== "undefined") {
        let map = new window.google.maps.Map(document.getElementById("map"), {
          center: myLatLng,
          zoom: 18,
        });
        new window.google.maps.Marker({
          position: myLatLng,
          map,
        });
      }
    }, 1000);
  }, [])

  const onConfirm = () => {
    if (!_isEmpty(formValue)) {
      const { address = " " } = formValue
        let finalValue = {
        ...formValue, ...{
          address
        }
      }
      setStorage("formData", finalValue)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "address_submit",
        dtL_address_submit: {
          address: address,
          branch: process.env.GATSBY_BRANCH
        },
      })
      // request({
      //   url: 'onkcrgp', data: {
      //     address
      //   }
      // }).then(() => {
      navigate(OFFER_STEP_SECOND_SCREEN)
      // })
    }
  }
  const { address = " " } = formValue

  return (<div className="addressVerifyContainer">
    <div className="mapLocation" id="map">
    </div>
    <div className="container mb-3">
      <div className="title text-center mt-4 pl-4 pr-4 font-weight-bold">{address}</div>
      <div className="addressTitle text-center text-muted mt-2">
        Is this address correct?
      </div>
      <div className="row mt-4 buttons pl-2 pr-2 mb-5">
        <div className="col-6 pr-2">
          <ButtonField to={OFFER_ADDRESS_EDIT_SCREEN} className="btn btn-outline-secondary btn-lg btn-block btnText" name=" Add unit # or fix address"/>
        </div>
        <div className="col-6 pl-2">
          <ButtonField className="btn bgYellow text-white btn-lg btn-block btnText" name="Yes, that's right" onClick={() => onConfirm()} />
        </div>
      </div>
    </div>
  </div>);
}

export default AddressVerify;